.ant-layout-header {
  background: #f0f2f5;
  height: auto;
  line-height: 1rem;
  margin: 0 0;
  padding: 24px 50px 0px;
  display: flex;
  justify-content: space-between;
  .logo {
    margin: auto 0;
    font-size: 2rem;
    cursor: pointer;
    p {
      margin-bottom: 10px;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    .button-group {
      .profile-button,
      .signout-button,
      .signup-button {
        margin-left: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-layout-header {
    display: block;
    text-align: center;
    .logo {
      font-size: 1.5rem;
      width: 100%;
    }
    .menu {
      display: block;
      .button-group {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        .profile-button,
        .signout-button,
        .signin-button,
        .signup-button {
          width: 50%;
          margin: 0 auto;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
