.signin-form,
.signin-google {
  .password {
    margin-bottom: 0;
  }

  .register-or-forgot {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .signin-button {
    margin-bottom: 0;
  }

  .button {
    width: 100%;
  }

  .or {
    margin: 0.5rem 0;
    text-align: center;
  }
}

.signup-form,
.signin-google {
  .confirmPassword {
    margin-bottom: 0;
  }

  .signin-link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  .signup-button {
    margin-bottom: 0;
  }

  .button {
    width: 100%;
  }

  .or {
    margin: 0.5rem 0;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
  .signin-form,
  .signin-google,
  .signup-form {
    width: 50%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 769px) {
  .signin-form,
  .signin-google,
  .signup-form {
    width: 40%;
    margin: 0 auto;
  }
}
