#root {
  background: radial-gradient(
    46.83% 46.83% at 50% 50%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 251, 247, 0.4375) 54.72%,
    #fff6ed 100%
  );
  .ant-layout {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background: transparent;
  }
}
