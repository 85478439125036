.home {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    img {
      width: 25%;
    }
  }

  .main-content {
    position: fixed;
    top: 35%;
    left: 0;
    min-height: 320px;
    margin: 0 auto;
    overflow: auto;
    width: 100%;
    .text {
      margin: 0;
      font-size: 1rem;
      span {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }
    .form {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
      .form-shop,
      .form-district {
        width: 20%;
        margin: 0;
        .ant-select-selector {
          border-radius: 5px;
          box-shadow: none;
          text-align: left;
          &:hover {
            border-color: #ffa43f;
            box-shadow: none;
          }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          border-color: #ffa43f;
        }
      }
      .form-district {
        margin-left: 20px;
      }
    }
    .button-wrapper {
      .submit-button {
        width: 200px;
        height: 40px;
        line-height: 1rem;
        margin: 0 auto;
        background: #ffa43f;
        border-color: transparent;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1rem;
        color: #ffffff;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        &:hover {
          animation-name: bounce;
        }
        -webkit-box-shadow: 0px 20px 30px 0px rgba(255, 164, 63, 1);
        -moz-box-shadow: 0px 20px 30px 0px rgba(255, 164, 63, 1);
        box-shadow: 0px 20px 30px 0px rgba(255, 164, 63, 1);
      }
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    font-size: 1em;
    .button {
      width: 150px;
      height: 40px;
      border-color: #ffa43f;
      color: #ffa43f;
      &:hover {
        border-color: #ffa43f;
        background-color: #ffa43f;
        color: #fff;
      }
    }
    .button-right {
      margin-left: 20px;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .main-content {
      top: 25%;
      .form {
        .form-shop,
        .form-district {
          width: 30%;
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .home {
    .main-content {
      top: 15%;
      .form {
        .form-shop,
        .form-district {
          width: 40%;
        }
      }
    }
  }
}
